import Payments from './Payments';

export default class PayzenPayment extends Payments {
  async formToken() {
    var FormData = require('form-data');
    var formData = new FormData();

    formData.append('Amount', this.cart?.Total?.ToPay?.TaxIncl * 100);
    formData.append('CurrencyIsoCode', this.cart?.Currency?.IsoCode);
    formData.append('IdCart', this.cart?.IdCart);

    try {
      const response = await this.http.API_AUTH.post(
        '/payment/payzen/token',
        formData
      );
      return response.data?.ClientToken;
    } catch (error) {
      throw error;
    }
  }

  async postData({ clientSecret, paymentMethod }) {
    var FormData = require('form-data');
    var formData = new FormData();

    formData.append('ClientSecret', clientSecret);
    formData.append(
      'Metadata',
      JSON.stringify(this.custom_data(paymentMethod))
    );

    try {
      const response = await this.http.API_AUTH.post(
        '/payment/stripe/data',
        formData
      );
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
